

















































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import { IllnessLookupModel, IllnessModel, IllnessQualityControlModel } from '@/core/webapi';
import { ReportLookupService, ApiService } from '@/core/services';
import { BaseFormComponent, InputTextareaCpt, InputSelectCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import {
  QcInputFieldCpt,
  QcInputDateCpt,
  QcInputSwitchWithTextareaCpt,
  QcInputSelectCpt,
  QcInputSwitchDateCpt,
  QcOsicsCpt,
  AnalystDataCleanupInfoCpt,
} from '../components';
import { QcStatus, StudyTypes } from '@/core/constants';

@Component({
  components: {
    InputTextareaCpt,
    InputSelectCpt,
    ValidationHintCpt,
    SpinnerCpt,
    QcInputFieldCpt,
    QcInputDateCpt,
    QcInputSwitchWithTextareaCpt,
    QcInputSelectCpt,
    QcInputSwitchDateCpt,
    QcOsicsCpt,
    AnalystDataCleanupInfoCpt,
  },
})
export default class IllnessFormView extends BaseFormComponent {
  lookups = {} as IllnessLookupModel;
  report = new IllnessModel({ modelState: {} } as any);
  qualityControl = new IllnessQualityControlModel({ modelState: {} } as any);
  isQcComplete = false;
  showDialog = false;
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };
  loading = true;

  get illnessId() {
    return this.getUrlParamAsNumber('illnessId');
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.report.organisationId, StudyTypes.Elite);
  }

  get isSevensStudy() {
    return this.studies?.find(p => p.id === this.report.studyId)?.gameFormatId === 2;
  }

  async created() {
    try {
      await this.initializeModels();
      await this.initializeLookupData();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    if (this.isQcComplete) {
      this.qualityControl.qcStatusId = QcStatus.Complete;
      await this.completeFormSubmission();
    } else {
      this.showDialog = true;
    }
  }

  onDialogClose() {
    this.showDialog = false;
  }

  async onDialogButton(requireReporterInfo: boolean) {
    this.qualityControl.qcStatusId = requireReporterInfo ? QcStatus.AwaitingReporter : QcStatus.AwaitingAnalyst;
    this.showDialog = false;
    await this.completeFormSubmission();
  }

  async completeFormSubmission() {
    this.qualityControl.id
      ? await ApiService.illnessQualityControl()
          .updateIllnessQualityControl(this.qualityControl)
          .handleValidationErrors(this.qualityControl)
      : await ApiService.illnessQualityControl()
          .createIllnessQualityControl(this.qualityControl)
          .handleValidationErrors(this.qualityControl);

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.illnessReviewSaved') as string,
      type: 'success',
    });

    this.$router.push('/illnesses');
  }

  private async initializeModels() {
    if (this.illnessId) {
      const response = (await ApiService.illnessQualityControl().getIllnessQualityControl(this.illnessId)).data;

      _.extend(this.report, response.report);
      _.extend(this.qualityControl, response.qualityControl);
      this.isQcComplete = response.qualityControl.qcStatusId === QcStatus.Complete;
    }
  }

  private async initializeLookupData() {
    this.lookups = (await ApiService.illnesses().getIllnessFormLookupData(this.report.playerId!)).data;
    ReportLookupService.resolveOrgStudyFilterIds(this.lookups, this.report, false, StudyTypes.Elite);
  }
}
