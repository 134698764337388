import { render, staticRenderFns } from "./IllnessGridView.vue?vue&type=template&id=5414a42a&scoped=true&"
import script from "./IllnessGridView.vue?vue&type=script&lang=ts&"
export * from "./IllnessGridView.vue?vue&type=script&lang=ts&"
import style0 from "./IllnessGridView.vue?vue&type=style&index=0&id=5414a42a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5414a42a",
  null
  
)

export default component.exports