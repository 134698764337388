




















































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { IllnessLookupModel, IllnessModel } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import {
  DetailHeaderCpt,
  DetailsTableRowCpt,
  DetailsDateTableRowCpt,
  DetailsLookupTableRowCpt,
  SpinnerCpt,
} from '@/core/components';
import { OsicsDetailsCpt, DetailsOsicsTableRowCpt } from '../components';
import { StudyTypes } from '@/core/constants';

@Component({
  components: {
    OsicsDetailsCpt,
    DetailHeaderCpt,
    DetailsTableRowCpt,
    DetailsDateTableRowCpt,
    DetailsLookupTableRowCpt,
    DetailsOsicsTableRowCpt,
    SpinnerCpt,
  },
})
export default class IllnessDetailsView extends AppVue {
  model = {} as IllnessModel;
  lookups = {} as IllnessLookupModel;
  loading = true;

  get illnessId() {
    return parseInt(this.$route.params.illnessId, 10);
  }

  get subTitle() {
    return `${this.model?.playerFirstName ?? ''} ${this.model?.playerLastName ?? ''}`.trim() ?? null;
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.organisationId, StudyTypes.Elite);
  }

  get selectedStudy() {
    return this.studies ? this.studies.find(p => p.id === this.model.studyId) : null;
  }

  async created() {
    try {
      this.model = (await ApiService.illnesses().getIllness(this.illnessId)).data;
      this.lookups = (await ApiService.illnesses().getIllnessFormLookupData(this.model.playerId!)).data;
    } finally {
      this.loading = false;
    }
  }
}
